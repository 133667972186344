import React, { Component } from 'react'
import get from 'lodash/get'
import Img from 'gatsby-image'
import {graphql} from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"

export default class portfolioTemplate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      image: 0
    }
  }

  toggleModal = (i) => {
    this.setState({show: true, image: i})
  }

  closeModal = () => {
    this.setState({show: false})
  }

  handleClick = (e) => {
    if (this.node.contains(e.target) || !e.keyCode === 27) {
      return;
    }
    this.setState({show: false})

  }

  nextPreviousImage = (button, length) => {
    let nextImage = this.state.image

    if (button === 'next') {nextImage++}
    if (button === "previous") {nextImage--}
    if (nextImage > length-1){nextImage = 0}
    if (nextImage < 0) {nextImage = length-1}

    this.setState({image: nextImage})

  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false)
    document.addEventListener('keydown', this.handleClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
    document.removeEventListener('keydown', this.handleClick, false)


  }


  render() {
    const item = get(this.props, 'data.contentfulPortfolioWork')
    const showHide = this.state.show ? "modal visible" : "modal invisible"
    return (
      <Layout>
        <SEO title={item.title} />
        <section className="mt-12 container">
        <h1 className="relative mb-8 orange-underline text-center">{item.title}</h1>
        <div className={showHide}>
          <div className="modal-main" ref={node => this.node = node}>
            <button aria-label="Next image" className="bg-orange text-gray-800 p-1 absolute z-40 pin-left" onClick={(e) => this.nextPreviousImage('next', item.images.length)}><i name="next" className="gg-chevron-left"/></button>
            <button aria-label="Previous image" className="bg-orange text-gray-800 p-1 absolute z-40 pin-right" onClick={(e) => this.nextPreviousImage('previous', item.images.length)}><i className="gg-chevron-right"/></button>
            <button aria-label="Close image" className="close-button" onClick={this.closeModal}><i className="gg-close-o" /></button>
            <Img className="max-height-9" fluid={item.images[this.state.image].fluid} />
          </div>
        </div>
        <div className="flex flex-wrap justify-center mb-12">
          {item.images.map((image, index) => (
            <div 
              key={index}
              onClick={() => this.toggleModal(index)}
              onKeyDown={() => this.toggleModal(index)} 
              role="button" 
              tabIndex={0}
              className="portfolio p-2"
            >
              <Img className={index > 0 ? "portfolio-image" : "max-height-9"} fluid={image.fluid} />
            </div>
          ))}
          </div>
        </section>
      </Layout>
    )
  }
}

export const query = graphql`
  query PortfolioBySlug($slug: String!) {
    contentfulPortfolioWork(slug: {eq: $slug}) {
    title
    updatedAt
    images {
      fluid(maxWidth: 1200) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
  }
`